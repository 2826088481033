import React from 'react';
import { useCart } from './CartContext';
import { FaShoppingCart } from 'react-icons/fa';
import styled from 'styled-components';

function Cart() {
    const {cart} = useCart();
    return (
        <Container>
            <Paragraph style={{
               color: cart?.length === 0 ? 'rgba(0, 128, 0, 0.6)' : 'green',

            }}>
                {cart.length}
            </Paragraph>
            <ResponsiveCartIcon style={{color: cart?.length === 0 ? 'rgba(0, 128, 0, 0.6)' : 'green',
}}/>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -12px;
    margin-left: 5px;
    cursor: pointer;
    `;

const ResponsiveCartIcon = styled(FaShoppingCart)`
    font-size: 28px;
    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

const Paragraph = styled.p`
    font-size: 10px;
    font-weight: 900;
    margin-bottom: -5px;
    margin-left: 2px;
    @media(max-width: 768px){
        font-size: 9px;
        margin-bottom: -2px;

    }
`;

export default Cart;

