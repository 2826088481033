import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useCart } from '../Cart/CartContext';
import Loading from '../Loading';
import baseUrl from '../../baseURL';
import Creatable from 'react-select/creatable'; 
import { components } from 'react-select';
import { FaPlus } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

const CustomDropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <FaPlus /> 
      </components.DropdownIndicator>
    );
  };
function Choice() {
    const [choice, setChoice] = useState([]);
    const [changedPrices, setChangedPrices] = useState([]); // Store dynamic prices
    const [isLoading, setIsLoading] = useState(true);
    const { addToCart } = useCart();

    function isMobileDevice() {
        return (
            (typeof window.orientation !== "undefined") ||
            (navigator.userAgent.indexOf('IEMobile') !== -1)
        );
    }
    const [isMobile, setIsMobile] = useState(isMobileDevice()); // Initial check

    useEffect(() => {
        // You might want to check on resize or other conditions as well
        window.addEventListener('resize', () => {
            setIsMobile(isMobileDevice());
        });

        return () => {
            window.removeEventListener('resize', () => {
                setIsMobile(isMobileDevice());
            });
        };
    }, []);

    useEffect(() => {
        const getChoice = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${baseUrl}/tea-house/choice`);
                setChoice(response.data);
                // Initialize dynamic prices
                const initialPrices = response.data.map(c => c.price);
                setChangedPrices(initialPrices);
            } catch (error) {
                console.error(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        getChoice();
    }, []);
    
    const customStyles = {
        menu: (provided) => ({
          ...provided,
          top: '-80px',
          left: '-50px',
          width: '120px',
          textAlign: 'center',
          zIndex: '1000'

        }),
        control:(provided) => ({
          ...provided,
          // Adjustments to the control's size (height, font size, etc.)
          minHeight: '5px', // Adjust height as needed
          height: '27px',
          margin: 'auto auto',
          display: 'flex', // Smaller font size for options
          alignItems: 'center',
        }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
          alignSelf: 'center', // Try 'center' or adjust as needed to align with the text
          padding: '0 8px', // Adjust padding as needed, can reduce if the space is too large
        }),
        input: (provided) => ({
            ...provided,
            outline: 'none', /* Remove the default outline */
            border: 'none',
            cursor: 'none',
            opacity: '0' /* Remove the default border */
          }),
          placeholder: (provided) => ({
            ...provided,
            cursor: 'none',
            opacity: '0',
          })
      };

    const handleChangePrice = (selectedOption, index) => {
        // Example logic to update price based on selected amount
        const newPrice = (selectedOption.value / choice[index].amount) * choice[index].price;
        let newPrices = [...changedPrices];
        newPrices[index] = newPrice.toFixed(2);
        setChangedPrices(newPrices);
    };

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
         <Helmet>
                    <meta name="description" content="Выбор чая Выбор китайского чая, китайский набор, столик для чая, столик для китайского чая, чайная продукция, китайская утварь, церемония, китайская чайная, китайская чайная в Израиле, китайская чайная тель-авив, попить чай, попить китайский чай, заказать мероприятие, мероприятие, чайная церемония, китайское место, чай, китайский, набор посуды, набор китайской посуды, Раздел Чая, Чай, Китайский чай, Китайская продукция, Тель-Авив, Израиль" />
                    <meta name="keywords" content="Выбор чая Выбор китайского чая, китайский набор, столик для чая, столик для китайского чая, чайная продукция, китайская утварь, церемония, китайская чайная, китайская чайная в Израиле, китайская чайная тель-авив, попить чай, попить китайский чай, заказать мероприятие, мероприятие, чайная церемония, китайское место, чай, китайский, набор посуды, набор китайской посуды, Раздел Чая, Чай, Китайский чай, Китайская продукция, Тель-Авив, Израиль" />
            </Helmet>
            <ChoiceDivStyled>
            {choice.map((ch, index) => (
                <ChoiceDiv key={index}>
                    <ChoiceTitleImgStyled>
                    <Helmet>
                        <meta name="description" content={`${ch?.description}`} />
                    </Helmet>
                        <ChoiceTitle>{ch.title}</ChoiceTitle>
                        <img src={ch.imageURL} alt={ch.title}/>
                    </ChoiceTitleImgStyled>
                    <ChoiceDescription>
                    <Helmet>
                        <meta name="keywords" content={`${ch?.title}`} />
                    </Helmet>
                        <p>{ch.description}</p>
                    </ChoiceDescription>
                    <AmountPrice>
                            <Amount>
                                <Creatable
                                isSearchable={!isMobile} 
                                onCreateOption={true} 
                                menuPosition="absolute"
                                styles={customStyles} 
                                placeholder='amount' 
                                defaultValue={{value: ch.amount, label: ch.amount}}
                                options={[
                                    {value: ch.amount, label: ch.amount},
                                    {value: (ch.amount * 2), label: ch.amount * 2},
                                    {value: (ch.amount * 3), label: ch.amount * 3},
                                    {value: (ch.amount * 4), label: ch.amount * 4},
                                    {value: (ch.amount * 5), label: ch.amount * 5}
                                ]}
                                onChange={(selectedOption) => handleChangePrice(selectedOption, index)}
                                components={{ DropdownIndicator: CustomDropdownIndicator }}
                                />
                            </Amount>
                    <Price>Цена: &#8362; <strong>{changedPrices[index]}</strong></Price>
                    </AmountPrice>

                    <Buttons>
                        <ButtonGreen onClick={() => addToCart(ch, changedPrices[index])}>Добавить</ButtonGreen>
                    </Buttons>
                </ChoiceDiv>
            ))}
           
        </ChoiceDivStyled>
        </>
        
    );
}






const ChoiceDivStyled = styled.div`
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-around;
    gap: 20px; 
    padding: 20px; 
    
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

const ChoiceDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center; 
    width: 100%;
    max-width: 20rem;
    cursor: pointer; 
    height: auto; 
    border-radius: 1rem;
    background-color: rgba(255, 255, 255, 0.7);
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
    @media(max-width: 768px){
        max-width: 12.5rem;
    }

    &:hover {
        transform: scale(1.05);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
    img {
        width: 80%; 
        margin-top: 1rem;
        border-radius: 0.8rem;
    }
    &:hover img {
        border: 2px solid #eee; 
    }
    
`;

const ChoiceTitleImgStyled = styled.div`
    display: flex;
    flex-direction: column; 
    align-items: center; 
    width: 100%; 
`;
const AmountPrice = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  gap: 10px;
`;

const Amount = styled.span`
    margin-top: -12px;
`;

const Price = styled.span`
  font-size: 15px;
  color: green;
  margin-bottom: 10px;
  

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const ChoiceTitle = styled.div`
    font-size: 1.5rem;
    text-align: center;
    width: 100%; 
    margin: 1rem 0; 

    @media (max-width: 768px) {
        font-size: 1.2rem; 
    }
`;

const ChoiceDescription = styled.div`
    text-align: center;
    padding: 0 1rem; 
    margin-bottom: 1rem; 
`;

const Buttons = styled.div`
    width: 100%; 
    display: flex;
    justify-content: center; 
    gap: 10px; 
    padding-bottom: 1rem; 
`;

const ButtonGreen = styled.button`
    padding: 0.5rem 1rem; 
    background-color: #4CAF50; 
    color: white;
    border-radius: 5px;
    cursor: pointer;
    width: 80%;
    transition: background-color 0.3s, transform 0.2s; 

    &:hover {
        background-color: #45a049; 
        transform: translateY(-2px); 
    }

    @media (max-width: 768px) {
        padding: 0.4rem 0.8rem; 
    }
`;
export default Choice;



