import axios from 'axios';
import React, {useState} from 'react';
import styled, {keyframes} from 'styled-components';
import baseUrl from '../../baseURL';
import { Link } from 'react-router-dom';
import CartLoading from '../CartLoading';
import { useCart } from './CartContext';

function CustomerDetails({summary, cart, close, isSentToCust, setIsSentToCust}) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [telephone, setTelephone] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const {removeAllFromCart} = useCart();
  const sendToTelegram = () => {
    const message = `Hello, here are the order details:\nName: ${name}\nEmail: ${email}\nTelephone: ${telephone}\nTotal: ${summary}\nProducts: ${cart.map(product => product.name + ' x ' + product.quantity).join(", ")}`;
    const telegramUrl = `https://t.me/+972547962374?text=${encodeURIComponent(message)}`;
    window.open(telegramUrl, '_blank');
  };
  const sendToWhatsApp = () => {
    // Correct the base URL to use a direct phone number URL
    const baseUrl = 'https://wa.me/972543098002'; // Example: Replace with your actual full phone number including country code

    const message = `Здравствуйте, вот подробности заказа:\nимя: ${name}\nпочта: ${email}\nтелефон: ${telephone}\nвсего: ${summary}\nтовары: ${cart.map(product => `${product.title} ${product.price}`).join(", ")}`;
    const encodedMessage = encodeURIComponent(message);
     
    // Construct the WhatsApp URL with the encoded message
    const url = `${baseUrl}?text=${encodedMessage}`; // Note: Remove the slash before '?text='

    // Open the WhatsApp URL in a new tab
    window.open(url, '_blank');
};

  
    const handleClick = (e) => {
        e.stopPropagation();
      };
    const sendEmailDetails = async() => {
      setIsLoading(true)
      try {
        if(name && email && telephone){
          await axios.post(`${baseUrl}/send-email`, {
            name, email, telephone,
            price: summary,
            products: cart
        })
          setIsSentToCust(true);
          removeAllFromCart();
          setIsSent(true);

        }
        
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);  
    }
    if (isLoading) {
      return <CartLoading />;
  }
  return (
    <>
    {!isSent ? (
         <CustomerDetailsContainer onClick={handleClick}>
      
         <Input placeholder="Имя" type='text' value={name} onChange={(e) => setName(e.target.value)} required/>
           <Input placeholder="Почта" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
           <Input placeholder="Телефон" type="tel" value={telephone} onChange={(e) => setTelephone(e.target.value)} required/>
         <SummarySpan>К оплате: &#8362; {summary}</SummarySpan>
         {summary > 0 && <Button onClick={sendEmailDetails}>Оформить</Button>}
      
       </CustomerDetailsContainer>
    ) : (
      <TextIsSentDiv>
        <SentText>
        Заказ принят. В ближайшее время мы свяжемся с вами для уточнения всех деталей.
        Способы оплаты и доставки вы можете посмотреть в разделе <Link to='/about#oplata' onClick={close} style={{marginRight: '5px', fontWeight: '900', color: 'green', textDecoration: 'none', borderBottom: '1px solid green', paddingBottom: '3px'}}>Информация.</Link>
        Спасибо!
        </SentText>
      </TextIsSentDiv>
    )}
    </>
   
  );
}
const CustomerDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 10px;
  text-transform: uppercase;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  margin: 5px 0;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
`;

const SummarySpan = styled.span`
  font-size: 16px;
  color: #333;
  padding-top: 10px;
`;
const Button = styled.button`
    height: 40px;
    width: 190px;
    background-color: #2e4f24;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    &:hover{
        background-color: green;
    }
`;
const TextIsSentDiv = styled.div`
    margin: auto;
    text-align: center;
`;
const SentText = styled.p`
    font-size: 14px;
    color: green;
`; 
const rotateAnimation = keyframes`
  0%, 100% {
    transform: translateY(0);
    transform: translateX(0);
  }
  25% {
    transform: translateY(-1px) rotate(-1deg);
    transform: translateX(-1px) rotate(-1deg);
  }
  50% {
    transform: translateY(1px) rotate(1deg);
    transform: translateX(1px) rotate(1deg);
  }
  75% {
    transform: translateY(-1px) rotate(-1deg);
    transform: translateX(-1px) rotate(-1deg);
  }
`;
const LoadingAnimation = styled.div`
  animation: ${rotateAnimation} 2s linear infinite;
  padding: 20px;
  font-size: 20px;
  text-align: center;
`;
export default CustomerDetails;
