import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import videoFile from '../video.mp4';
import logo from '../NavAndFooter/logoBej.png';
import { useLocation } from 'react-router-dom';
import Loading from '../Loading';

function About() {
  const location = useLocation();
  const videoRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 140) {
        videoRef.current.muted = true;
      } else {
        videoRef.current.muted = false;
      }
    };
    
    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup function to remove event listener
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const videoElement = videoRef.current;  
    const handleCanPlay = () => {
      setIsLoading(false); 
    };
  
   
  
    const handleError = () => {
      setIsLoading(false); 
    };
  
    videoElement.addEventListener('canplay', handleCanPlay);
    // videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
    videoElement.addEventListener('error', handleError);
  
    return () => {
      videoElement.removeEventListener('canplay', handleCanPlay);
    //   videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
      videoElement.removeEventListener('error', handleError);
    };
  }, []);
  useEffect(() => {
    if (location.hash) {
      const elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth' });
      }
    }

    // Setup autoplay for Safari as well, since autoplay without user interaction
    // might not always work
    if (isSafari() && videoRef.current) {
      videoRef.current.play().catch(error => {
        console.log("Autoplay was prevented:", error);
      });
    }
  }, [location]);
  
  return (
    <AboutContainer>
        {isLoading && <Loading />}
      <Section>
        <Title>Немного О Нас</Title>
        <Content background={logo}>
        Чайный магазин "Tea House TLV" - не просто место для покупки чая онлайн. Это настоящее чайное пространство, где каждый может найти для себя что-то особенное. Наша история в Израиле началась в 2020 году, и на сегодняшний день мы стали любимым местом для многих ценителей китайского чая. Ассортимент, всегда представленный в наличии, включает все популярные категории китайских чаев, от шу и шен пуэров до светлых и темных улунов. Также у нас имеется широкий выбор чайной посуды и инструментов для чайной церемонии.
        <br></br>
        В чайном пространстве "Tea House TLV" вы не только сможете получить свой заказ лично и, при желании, выбрать чай или посуду, но и расслабиться, выпив чая со своими друзьями в приятной и непринужденной обстановке. Также мы предлагаем выездные чайные церемонии. У нас чай становится не просто напитком, а источником радости и вдохновения.
        <br></br>
        Чайное пространство находится по адресу: Menora 28, Tel Aviv. 
        Сообщайте пожалуйста заранее, когда планируете нас посетить.
        </Content>
        <Video
          ref={videoRef}
          autoPlay
          loop
          playsInline
          src={videoFile}
          type="video/mp4"
        >
          Your browser is not supported!
        </Video>
      </Section>

      <Section>
        <Title>Оплата и Доставка</Title>
        <Content id='oplata'>
        После оформления заказа на нашем сайте мы свяжемся с вами и вы выберете максимально удобный для вас способ оплаты и доставки.
        <br></br>
        Оплата<br></br>
        1. Онлайн-оплата: Мы принимаем оплату через платёжное приложение BIT.<br></br> 
        2. Оплата при получении: Вы можете оплатить заказ наличными при получении товара непосредственно в чайном пространстве. В некоторых случаях данный способ оплаты доступен при курьерской доставке.<br></br>
        3. Банковский перевод: При выборе этого способа оплаты, вы получите наши реквизиты для проведения банковского перевода. Пожалуйста, учитывайте время совершения транзакции при расчете сроков доставки.<br></br>

        Доставка<br></br>
        4. Курьерская доставка: Наши курьеры оперативно доставят заказ прямо до двери вашей квартиры или офиса. В некоторых случаях это может быть сотрудник курьерской службы.<br></br>
        5. Самовывоз: Вы всегда можете забрать свой заказ самостоятельно из нашего чайного пространства "Tea House TLV". Мы будем рады видеть вас и при необходимости, помочь с выбором чая или посуды на месте.<br></br>
        6. Выездная чайная церемония: Хотите устроить особый чайный праздник? Мы предлагаем услуги выездной чайной церемонии на ваше мероприятие или торжество. Свяжитесь с нами для уточнения деталей.<br></br>
        7. Доставка почтой: Для клиентов в отдалённых регионах и других странах осуществляется доставка почтой. Сроки получения заказа зависят от вашего местоположения.<br></br>

        Мы ценим ваше время и комфорт, поэтому стараемся предоставить максимально гибкие и удобные варианты оплаты и доставки. Если у вас есть какие-либо вопросы или специальные пожелания, свяжитесь с нами любым удобным для вас способом. Мы всегда готовы помочь!<br></br>
        </Content>
      </Section>

      <Section>
        <Title>Накопительные бонусы и скидки<br></br></Title>
        <Content>
        Мы ценим каждого нашего клиента и стремимся сделать ваше чаепитие еще более приятным. Поэтому предлагаем специальные программы накопительных бонусов и различные скидки для постоянных клиентов.<br></br>
        Программа накопительных бонусов:<br></br>
        Каждая ваша покупка в "Tea House TLV" приносит вам бонусные баллы. С накоплением баллов вы можете получить дополнительные скидки на свои следующие покупки или обменять баллы на подарки и бонусные предложения.<br></br>
        Скидки для постоянных клиентов:<br></br>
        Мы ценим ваше доверие и предлагаем специальные скидки для наших постоянных клиентов. При регулярных покупках у нас вы получаете доступ к эксклюзивным предложениям и скидкам на лучшие сорта чая, посуду и аксессуары.<br></br>
        Акции и специальные предложения:<br></br>
        Следите за нашими акциями и специальными предложениями, чтобы получить еще больше выгоды от своих покупок. Мы регулярно проводим акции с дополнительными скидками, подарками и бонусами для наших клиентов.<br></br>

        Присоединяйтесь к нашим программам и получайте больше радости от чаепития в "Tea House TLV"!
        </Content>
      </Section>
      <Section>
      <Title>Как правильно заваривать чай</Title>
      <Content>
      Чай - это не просто напиток, а целый ритуал, который приносит удовольствие и умиротворение. Чтобы получить максимум аромата и вкуса от вашего чая, следуйте этим простым рекомендациям по правильному завариванию:
      <br></br>
      1. Выберите правильную температуру воды: Для различных видов чая подходят разные температуры воды. Например, белый или зеленый чай обычно заваривают при температуре около 75-85 градусов Цельсия, в то время как тёмные чаи лучше заваривать при температуре около 90-95 градусов.<br></br>
      2. Используйте свежую воду: Качество воды влияет на вкус и аромат чая. Используйте свежую, фильтрованную или бутилированную воду для заваривания чая.<br></br>
      3. Выберете правильное количество чая: Определите оптимальное количество чая в зависимости от вида и сорта. Обычно при проведении чайной церемонии заваривается 5 гр. на человека. При заваривании чая в термосе 1 гр. на 100 мл. воды.<br></br>
      4. Время заваривания: Время заваривания также зависит от вида чая. Например, в первые несколько проливов белого и зеленого чая, не рекомендуется держать его долго в заварочном чайнике, готовый напиток может начать горчить. Чай тёмных сортов можно заваривать подольше.<br></br>
      5. Используйте ситечко: Чтобы избежать частиц и мелких фракций чая в напитке, используйте ситечко и чайник с встроенным фильтром.<br></br>
      6. Наслаждайтесь процессом: Заваривание чая - это не только его приготовление, но и целый ритуал. Наслаждайтесь ароматом чая, наблюдайте, как листья раскрывают свой изысканный вкус и аромат!<br></br>
<br></br>
      Следуя этим рекомендациям, вы сможете получить максимальное удовольствие от чаепития и насладиться всеми нюансами вкуса любимого чая.
      </Content>
      </Section>
    </AboutContainer>
  );
}

const AboutContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-top: 130px;
  min-height: 80vh;
`;

const Section = styled.section`
  margin: 20px 0;
  width: 80%;
  max-width: 800px;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);

  @media(max-width: 768px){
    max-width: 500px;
  }
`;

const Title = styled.h2`
  color: #333;
  font-size: 24px;
`;

const Content = styled.p`
  color: #666;
  font-size: 16px;
  background-image: url(${props => props.background});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Video = styled.video`
  width: 100%;
  border-radius: 8px;
  height: 400px;
  margin: auto;
  object-fit: cover;
  @media(max-width: 768px){
    height: 230px;
  }
`;

export default About;


