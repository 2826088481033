import React from 'react'
import {FaWhatsapp, FaInstagram, FaFacebook, FaTelegram, FaPhone} from 'react-icons/fa';
import logo from './logoBej.png';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
function Footer() {
  const phoneNumber = '+972543098002';
  const navigate = useNavigate();
  const handleNavigate = () => {
        navigate('/');
  }
  return (
    <FooterDiv>
      <IconsFooter>
      <OneIconFooter><a target="_blank" href="https://facebook.com/teahouse.tlv?mibextid=LQQJ4d" style={{textDecoration: 'none', color: '#eae3d1'}}><FaFacebook/></a></OneIconFooter>
      <OneIconFooter><a target="_blank" href="https://www.instagram.com/invites/contact/?i=hankfgrgy3i8&utm_content=nab5gi9" style={{textDecoration: 'none', color: '#eae3d1'}}><FaInstagram/></a></OneIconFooter>

      <OneIconFooter><a className='phone' href={`tel:${phoneNumber}`} style={{textDecoration: 'none', color: '#eae3d1'}}><FaPhone/></a></OneIconFooter>
      <OneIconFooter><a target="_blank" href="https://t.me/teahouse_tlv" style={{textDecoration: 'none', color: '#eae3d1'}}><FaTelegram/></a></OneIconFooter>
      <OneIconFooter><a target="_blank" href="https://wa.me/message/YR2HIE5KF5HEB1" style={{textDecoration: 'none', color: '#eae3d1'}}><FaWhatsapp/></a></OneIconFooter>

     </IconsFooter>
     <Line/>
      <ImageFooter>
        <img src={logo} alt="" onClick={handleNavigate}/>
      </ImageFooter>
    </FooterDiv>
  )
}
const FooterDiv = styled.footer`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #2e4f24;
    height: 25vh;

    @media(max-width: 768px){
      height: 30vh;
      
    }
`;
const IconsFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    margin-top: -10px;
    @media(max-width: 768px){
      margin-top: 0px;
    }
`;
const OneIconFooter = styled.div`
    margin: 1.8rem;
    display: inline-flex;
    font-size: 2rem;
    color:#eae3d1;
    cursor: pointer; 
    .phone{
      display: inline-flex; 
      align-items: center; 
      justify-content: center; 
      border: 2px solid #eae3d1; 
      border-radius: 50%; 
      padding: 10px;

      &:hover{
        background-color: #fff;
      }
    }
    @media(max-width: 768px){
        margin: .8rem;
        font-size: 1.1rem;
    }

`;
const Line = styled.div`
    width: 90%;
    background-color: #eae3d1;
    height: 1px;
    margin-top: -10px;
    @media(max-width: 768px){
      margin-top: 0px;
    }
`;
const ImageFooter = styled.div`
    margin: auto;
    img{
      width: 175;
      height: 70px;
      @media(max-width: 768px){
        width: 155px;
        height: 55px;
      }
    }
`
export default Footer