import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import Creatable from 'react-select/creatable';
import { useCart } from '../Cart/CartContext';
import { components } from 'react-select';
import { FaPlus } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
const CustomDropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <FaPlus />
      </components.DropdownIndicator>
    );
};

function OneProductItem() {
    const location = useLocation();
    const state = location.state;
    const product = state ? state.product : null;
    const [changedPrice, setChangedPrice] = useState(product ? product.price : 0);
    const { addToCart } = useCart();
    function isMobileDevice() {
        return (
            (typeof window.orientation !== "undefined") ||
            (navigator.userAgent.indexOf('IEMobile') !== -1)
        );
      }
      const [isMobile, setIsMobile] = useState(isMobileDevice()); // Initial check
    
    useEffect(() => {
        // You might want to check on resize or other conditions as well
        window.addEventListener('resize', () => {
            setIsMobile(isMobileDevice());
        });
    
        return () => {
            window.removeEventListener('resize', () => {
                setIsMobile(isMobileDevice());
            });
        };
    }, []);
    const options = product ? [
        { value: product.amount, label: product.amount.toString() },
        { value: product.amount * 2, label: (product.amount * 2).toString() },
        { value: product.amount * 3, label: (product.amount * 3).toString() },
        { value: product.amount * 4, label: (product.amount * 4).toString() },
        { value: product.amount * 5, label: (product.amount * 5).toString() }
    ] : [];

    const customStyles = {
      menu: (provided) => ({
        ...provided,
        top: '-70px',
        left: '-10px',
        width: '200px',
        textAlign: 'center',
        zIndex: '1000'

      }),
      control: (provided) => ({
        ...provided,
        minHeight: 'auto',
        height: 'auto',
        margin: 'auto auto',
        marginTop: '14px',
        display: 'flex',
        alignItems: 'center',
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding: '6px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }),
      input: (provided) => ({
        ...provided,
        outline: 'none', /* Remove the default outline */
        border: 'none',
        cursor: 'none',
        opacity: '0' /* Remove the default border */
      }),
      placeholder: (provided) => ({
        ...provided,
        cursor: 'none',
        // opacity: '0' /* Remove the default outline */
      })
    };

    const ChangePrice = (selectedOption) => {
        const selectedIndex = options.findIndex(option => option.value === selectedOption.value);
        const result = (options[selectedIndex].value / product.amount) * product.price;
        setChangedPrice(result.toFixed(2));
    };

    return (
        <OneProductDiv>
            <Helmet>
                    <meta name="description" content="Выбор чая Выбор китайского чая, китайский набор, столик для чая, столик для китайского чая, чайная продукция, китайская утварь, церемония, китайская чайная, китайская чайная в Израиле, китайская чайная тель-авив, попить чай, попить китайский чай, заказать мероприятие, мероприятие, чайная церемония, китайское место, чай, китайский, набор посуды, набор китайской посуды, Раздел Чая, Чай, Китайский чай, Китайская продукция, Тель-Авив, Израиль" />
                    <meta name="keywords" content="Выбор чая Выбор китайского чая, китайский набор, столик для чая, столик для китайского чая, чайная продукция, китайская утварь, церемония, китайская чайная, китайская чайная в Израиле, китайская чайная тель-авив, попить чай, попить китайский чай, заказать мероприятие, мероприятие, чайная церемония, китайское место, чай, китайский, набор посуды, набор китайской посуды, Раздел Чая, Чай, Китайский чай, Китайская продукция, Тель-Авив, Израиль" />
            </Helmet>
            <Container>
            <Helmet>
                    <meta name="description" content={`${product?.description}`} />
                    <meta name="keywords" content={`${product?.title}`} />
            </Helmet>
                <ProductDetails>
                    <h1>{product.title}</h1>
                    <p className="information">{product.description}</p>
                    <ControlDiv>
                        <span className="amount">
                            <Creatable 
                                isSearchable={!isMobile} 
                                onChange={(selectedOption) => ChangePrice(selectedOption)}
                                onCreateOption={true}
                                menuPosition="absolute"
                                placeholder={options[0].value}
                                options={options}
                                styles={customStyles}
                                components={{ DropdownIndicator: CustomDropdownIndicator }}
                            />
                        </span>
                        <ButtonsAndPrice>
                            <span className="price">Цена: <strong>{changedPrice}</strong></span>
                            <button className="buy" onClick={() => addToCart(product, changedPrice)}>Добавить</button>
                        </ButtonsAndPrice>
                    </ControlDiv>
                </ProductDetails>
                <ProductImage>
                    <img src={product.imageURL} alt="Product"/>
                    <Info>
                        <h2>Описание</h2>
                        <ul>
                            <li><strong>Название: {product.title}</strong></li>
                            <li><strong>Цена: {product.price}</strong></li>
                            <li><strong>Кол-во: {product.amount}</strong></li>
                        </ul>
                    </Info>
                </ProductImage>
            </Container>
        </OneProductDiv>
    );
}

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const scaleUp = keyframes`
  from { transform: scale(0.98); }
  to { transform: scale(1); }
`;

const OneProductDiv = styled.div`
    padding-top: 150px;
    animation: ${fadeIn} 1.5s ease-out;
    @media (max-width: 768px) {
        padding-top: 100px;
    }
`;

const Container = styled.div`
    display: flex;
    box-shadow: 0 15px 30px 1px rgba(128, 128, 128, 0.31);
    background: rgba(255, 255, 255, 0.90);
    text-align: center;
    border-radius: 5px;
    overflow: hidden;
    margin: 2.5rem auto;
    height: 450px;
    width: 80%;
    animation: ${scaleUp} 0.5s ease-out;
    @media (max-width: 768px) {
        flex-direction: column-reverse;
        height: auto;
    }
`;

const ProductDetails = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    overflow: hidden;
    padding: 30px;
    flex: 40%;
    background-color: #eae3d1;
    h1 {
        font-family: 'Old Standard TT', serif;
        font-size: 30px;
        color: #344055;
        margin: 0;
        @media (max-width: 768px) {
            font-size: 14px;
            white-space: nowrap;
            margin-top: 5px;
        }
    }
    p {
        font-family: 'Farsan', cursive;
        font-size: 14px;
        color: #7d7d7d;
        @media (max-width: 768px) {
            font-size: 10px;
        }
    }
`;

const ControlDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: auto;
    justify-content: center;
    span {
        margin: auto;
        @media (max-width: 768px) {
            margin-top: 35px;
        }
    }
    .price {
        font-size: 18px;
        margin: 10px 0;
        @media (max-width: 768px) {
            font-size: 13px;
        }
    }
    /* CSS hack to target Safari 10.1+ */
    @supports (-webkit-overflow-scrolling: touch) {
        /* Adjustments specifically for Safari */
        margin-top: 20px; /* Adjust the top margin */
    }
    /* Another method using a webkit-specific feature */
    @media not all and (min-resolution:.001dpcm) { 
        @supports (-webkit-appearance:none) {
            /* Safari-only styles */
            margin-top: 20px; /* Further adjust for Safari if needed */
        }
    }
`;

const ButtonsAndPrice = styled.div`
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    button {
        background: #49C608;
        border-radius: 5px;
        cursor: pointer;
        outline: none;
        border: none;
        color: #eee;
        font-family: 'Farsan', cursive;
        padding: 8px 26px;
        font-size: 1.2rem;
        width: 230px;
        margin: auto;
        transition: all 0.3s ease;
        font-weight: 900;
        &:hover {
            transform: translateY(-4px);
            background-color: darken(#49C608, 10%);
        }
        @media (max-width: 768px) {
            width: 150px;
            font-size: .9rem;
            padding: 6px 16px;
        }
    }
`;

const ProductImage = styled.div`
    position: relative;
    overflow: hidden;
    flex: 60%;
    cursor: pointer;
    img {
        width: 50%;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        transition: transform 0.3s ease-out;
        @media (max-width: 768px) {
            object-fit: contain;
        }
        &:hover {
            transform: scale(1.1);
        }
    }
`;

const Info = styled.div`
    background: rgba(27, 26, 26, 0.9);
    font-family: 'Farsan', cursive;
    transition: all 0.3s ease-out;
    transform: translateX(-100%);
    position: absolute;
    line-height: 1.9;
    text-align: left;
    font-size: 120%;
    cursor: no-drop;
    color: #FFF;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    h2 {
        text-align: center;
    }
    &:hover {
        transform: translateX(0);
    }
    ul li {
        transition: 0.3s ease;
        &:hover {
            transform: translateX(150px) scale(1.4);
        }
    }
`;

export default OneProductItem;



