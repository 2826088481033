import React from 'react';
import styled from 'styled-components';
import {FaWhatsapp, FaInstagram, FaFacebook, FaTelegram, FaPhone} from 'react-icons/fa';
import logo from './logoBej.png';
import { useNavigate } from 'react-router-dom';

function Navbar() {
    const phoneNumber = '+972543098002';
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate('/')
    }
  return (
       <NavBarStyled>
            
            <SocialIconsStyled>
            <IconStyled><a target="_blank" href="https://facebook.com/teahouse.tlv?mibextid=LQQJ4d" style={{textDecoration: 'none', color: '#eae3d1'}}><FaFacebook/></a></IconStyled>
            <IconStyled><a target="_blank" href="https://www.instagram.com/invites/contact/?i=hankfgrgy3i8&utm_content=nab5gi9" style={{textDecoration: 'none', color: '#eae3d1'}}><FaInstagram/></a></IconStyled>
               
            <IconStyled><a className='telephone' href={`tel:${phoneNumber}`} style={{textDecoration: 'none'}}><FaPhone/></a></IconStyled>
            <IconStyled><a target="_blank" href="https://t.me/teahouse_tlv" style={{textDecoration: 'none', color: '#eae3d1'}}><FaTelegram/></a></IconStyled>
            <IconStyled><a target="_blank" href="https://wa.me/message/YR2HIE5KF5HEB1" style={{textDecoration: 'none', color: '#eae3d1'}}><FaWhatsapp/></a></IconStyled>

            </SocialIconsStyled>
            <LogoDivStyled>
                    <img src={logo} onClick={handleNavigate}/>
            </LogoDivStyled>
              
        </NavBarStyled>
  )
}
const NavBarStyled = styled.nav`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 5rem;
    z-index: 10;
    position: fixed;
    background: #2e4f24;

    @media (max-width: 768px){
        flex-direction: column;
        height: 4.4rem;
        justify-content: center;
    }
`;
const LogoDivStyled = styled.div`
    width: 140px;
    height: 60%;
    img{
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
    @media(max-width: 768px){
        text-align: center;
        img{
            width: 55%;
            height: 80%;
    

        }
    }
`;

const SocialIconsStyled = styled.div`
    display: flex;
    justify-content: space-evenly;

    
`;
const IconStyled = styled.div`
    margin: 1rem;
    display: inline-flex;
    font-size: 2rem;
    color:#eae3d1;
    cursor: pointer; 
    .telephone{
        color: #fff;
        margin-right: 19px;
        margin-left: 19px;
        @media(max-width: 768px){
            margin-right: 10px;
            margin-left: 10px;
        }
    }
    &:hover {
        transform: scale(1.2); 
        color: teal; 
    }

    svg {
        transition: transform 0.2s ease-in-out, color 0.2s ease-in-out; 
    }   
    
    @media (max-width: 768px){
        margin: 0.4rem;
        font-size: 1rem;
    }
`;
export default Navbar;