import React, { useState, useEffect } from 'react';
import { useEvents } from './EventsContext';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Loading from '../Loading'; 
import { Helmet } from 'react-helmet';
function AllEvents() {
    const { events } = useEvents();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true); 

    useEffect(() => {
        if (events.length > 0) {
            setIsLoading(false); 
        }
    }, [events]);

    const handleNavigate = (event) => {
        navigate(`/tea-house/events/${event.id}`, { state: { event } })
    };

    if (isLoading) {
        return <Loading />; 
    }

    return (
      <>
      <Helmet>
                    <meta name="description" content="Выбор чая Выбор китайского чая, китайский набор, столик для чая, столик для китайского чая, чайная продукция, китайская утварь, церемония, китайская чайная, китайская чайная в Израиле, китайская чайная тель-авив, попить чай, попить китайский чай, заказать мероприятие, мероприятие, чайная церемония, китайское место, чай, китайский, набор посуды, набор китайской посуды, Раздел Чая, Чай, Китайский чай, Китайская продукция, Тель-Авив, Израиль" />
                    <meta name="keywords" content="Выбор чая Выбор китайского чая, китайский набор, столик для чая, столик для китайского чая, чайная продукция, китайская утварь, церемония, китайская чайная, китайская чайная в Израиле, китайская чайная тель-авив, попить чай, попить китайский чай, заказать мероприятие, мероприятие, чайная церемония, китайское место, чай, китайский, набор посуды, набор китайской посуды, Раздел Чая, Чай, Китайский чай, Китайская продукция, Тель-Авив, Израиль" />
            </Helmet>
            <ContainerEvents>
            {events?.map((event) => (
                <EventsBox key={event.id} onClick={() => handleNavigate(event)}>
                  <Helmet>
                    <meta name="description" content={`${event?.description}`} />
                    <meta name="keywords" content={`${event?.title}`} />
                </Helmet>
                    <ContentContainer>
                        {event?.imageURL ?<Image src={event.imageURL} alt={event.title} />
                        : <Video poster={event?.videoImageURL}><source src={event?.videoURL} playsInline type="video/mp4" />
                        Your browser does not support the video tag.</Video>}
                        <TitleDiv>
                            <Title>{event.title}</Title>
                        </TitleDiv>
                    </ContentContainer>
                </EventsBox>
            ))}
            
        </ContainerEvents>
      </>
        
    );
}

const ContainerEvents = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  padding-top: 190px;
  margin: auto;

`;

const EventsBox = styled.div`
  flex: 0 1 calc(33.333% - 20px); 
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  background-color: rgba(0,0,0,0.2);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
    background-color: rgba(0,0,0,0.7);

  }
  @media(max-width: 768px){
    flex: 0 1 calc(50% - 20px);
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  transition: background-color 0.3s ease;
`;

const Image = styled.img`
  width: 290px;
  height: 290px; /* Fixed height for uniformity */
  object-fit: cover;
  transition: transform 0.3s ease;
  @media(max-width: 768px){
    width: 110px;
    height: 110px;
  }
  ${EventsBox}:hover & {
    transform: scale(1.05); /* Slight zoom on hover */
  }
`;
const Video = styled.video`
width: 290px;
height: 290px; /* Fixed height for uniformity */
object-fit: cover;
transition: transform 0.3s ease;
@media(max-width: 768px){
  width: 110px;
  height: 110px;
}
${EventsBox}:hover & {
  transform: scale(1.05); /* Slight zoom on hover */
}
`;
const TitleDiv = styled.div`
  text-align: center;
  margin-top: 10px;
  @media(max-width: 768px){
        margin-top: 6px;
        white-space: nowrap;
  }
`;

const Title = styled.h3`
  font-size: 1.2rem;
  color: green;
  transition: color 0.3s ease;
  @media(max-width: 768px){
        font-size: .5rem;
  }
  ${EventsBox}:hover & {
    color: #fff; /* Change title color on hover */
    font-weight: 900;
  }
`;

export default AllEvents;



