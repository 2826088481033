import React, { createContext, useContext, useState, useEffect } from 'react';
import baseUrl from '../../baseURL';
import axios from 'axios';
const EventsContext = createContext();

export const EventsProvider = ({ children }) => {
  const [events, setEvents] = useState([]);
  useEffect(() => {
    const fetchEvents = async () => {
        try {
            const response = await axios.get(`${baseUrl}/tea-house/events`);
            setEvents(response.data);
        } catch (error) {
            console.log(error.message);
        }
    };

    fetchEvents();
}, []);
  return (
    <EventsContext.Provider value={{ events }}>
      {children}
    </EventsContext.Provider>
  );
};

export const useEvents = () => {
  return useContext(EventsContext);
};