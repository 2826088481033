import React, { createContext, useContext, useState } from 'react';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const addToCart = (product, price) => {
    const newProduct = {image: product?.imageURL, title: product?.title, price}
    setCart([...cart, newProduct]);
  };

  const removeFromCart = (productIndex) => {
    setCart(cart.filter((item, index) => index !== productIndex));
  };
  const removeAllFromCart = () => {
    setCart([]);
  }
  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, removeAllFromCart }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  return useContext(CartContext);
};
