import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Cart from '../Cart/Cart';
import { useNavigate } from 'react-router-dom';
import CartPopup from '../Cart/CartPopup';
function MovingNavbar() {
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, visible]);
  const navigateToHome = () => {
      navigate('/');
  }
  const navigateToAbout = () => {
      navigate('/about')
  }
  const handleProducts = () => {
    navigate('/tea-house/allproducts')
  }
  const handleNavigateToEvents = () => {
    navigate('tea-house/allevents')
  }
  return (
    <Navbar visible={visible}>
      <NavItem onClick={navigateToHome}>Главная</NavItem>
      <NavItem onClick={navigateToAbout}>Информация</NavItem>
      <NavItem onClick={handleProducts}>Продукция</NavItem>
      <NavItem onClick={handleNavigateToEvents}>Эвенты</NavItem>
      <CartDiv onClick={togglePopup}>
        <Cart/>
          {showPopup && <CartPopup close={togglePopup}/>}
      </CartDiv>

    </Navbar>
  );
}

const Navbar = styled.nav`
  position: fixed;
  width: 100%;
  background: #eae3d1;
  color: #fff;
  transition: top 0.3s;
  top: ${({ visible }) => (visible ? '0' : '-65px')};
  display: flex;
  margin-top: 5rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  z-index: 100;

  @media (max-width: 768px) {
    height: 1.8rem;
    margin-top: 4.4rem;
    top: ${({ visible }) => (visible ? '0' : '-45px')};
  }
`;

const NavItem = styled.div`
  color: #2e4f24;
  cursor: pointer;
  font-size: 1.2rem;
  margin: 1rem;
  transition: all .2s;
  &:hover{
    border-top: 2px solid #2e4f24;
    border-bottom: 2px solid #2e4f24;
  }
  @media (max-width: 768px) {
    font-size: 0.7rem;
    margin: 4px;
  }
`;
const CartDiv = styled.div`
    margin-left: 75px;

    @media (max-width: 768px) {
      width: 10%; 
      margin: 10px 0; 
    }
`;
export default MovingNavbar;
