import React  from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEvents } from './EventsContext';
import Loading from '../Loading'; 
import { Helmet } from 'react-helmet';
const Events = () => {
    const { events, isLoading } = useEvents(); 
    const navigate = useNavigate();

    const handleNavigate = (event) => {
        navigate(`/tea-house/events/${event.id}`, { state: { event } })
    };

    const settings = {
        dots: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        nextArrow: (
            <div>
              <div className="next-slick-arrow">...</div>
            </div>
        ),
        lazyLoad: true,
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1,
                    dots: false
                },
            },
        ]
    };

    if (isLoading) {
        return <Loading />; 
    }

    return (
        <>
         <Helmet>
                    <meta name="description" content="Выбор чая Выбор китайского чая, китайский набор, столик для чая, столик для китайского чая, чайная продукция, китайская утварь, церемония, китайская чайная, китайская чайная в Израиле, китайская чайная тель-авив, попить чай, попить китайский чай, заказать мероприятие, мероприятие, чайная церемония, китайское место, чай, китайский, набор посуды, набор китайской посуды, Раздел Чая, Чай, Китайский чай, Китайская продукция, Тель-Авив, Израиль" />
                    <meta name="keywords" content="Выбор чая Выбор китайского чая, китайский набор, столик для чая, столик для китайского чая, чайная продукция, китайская утварь, церемония, китайская чайная, китайская чайная в Израиле, китайская чайная тель-авив, попить чай, попить китайский чай, заказать мероприятие, мероприятие, чайная церемония, китайское место, чай, китайский, набор посуды, набор китайской посуды, Раздел Чая, Чай, Китайский чай, Китайская продукция, Тель-Авив, Израиль" />
            </Helmet>
            <EventsDivStyled>
            <ContentDiv>
                <HeaderDiv>Мероприятия Tea House</HeaderDiv>
                <Helmet>
                        <meta name="description" content="Мероприятия Tea House, Мероприятия, Tea House, Чай, Тель-Авив, Тель Авив, Мероприятия Тель-Авив, Мероприятия Тель Авив" />
                        <meta name="keywords" content="Мероприятия Tea House, Мероприятия, Tea House, Чай, Тель-Авив, Тель Авив, Мероприятия Тель-Авив, Мероприятия Тель Авив, Китайский чай Израиль" />
                </Helmet>
                <ContainerDiv>
                    <Slider {...settings}>
                        {events?.map((item, index) => (
                            <div key={index} onClick={() => handleNavigate(item)}>
                            <ImageVideoBodyDiv>
                                   {item?.imageURL ? <img src={item?.imageURL} alt={item.title} />
                                   : <video poster={item?.videoImageURL}>
                                    <source src={item.videoURL} controls playsInline type="video/mp4" />
                                    {/* <source controls src={item.videoURL} type="video/webm" /> */}

                                            Your browser does not support the video tag.
                                    </video>}

                                </ImageVideoBodyDiv>
                                <Helmet>
                                     <meta name="description" content={`${item?.description}`} />
                                    <meta name="keywords" content={`${item?.title}`} />
                                </Helmet>
                                <div>
                                    <h2>{item.title}</h2>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </ContainerDiv>
            </ContentDiv>
            <Helmet>
                    <meta name="description" content="Выбор чая Выбор китайского чая, китайский набор, столик для чая, столик для китайского чая, чайная продукция, китайская утварь, церемония, китайская чайная, китайская чайная в Израиле, китайская чайная тель-авив, попить чай, попить китайский чай, заказать мероприятие, мероприятие, чайная церемония, китайское место, чай, китайский, набор посуды, набор китайской посуды, Раздел Чая, Чай, Китайский чай, Китайская продукция, Тель-Авив, Израиль" />
                    <meta name="keywords" content="Выбор чая Выбор китайского чая, китайский набор, столик для чая, столик для китайского чая, чайная продукция, китайская утварь, церемония, китайская чайная, китайская чайная в Израиле, китайская чайная тель-авив, попить чай, попить китайский чай, заказать мероприятие, мероприятие, чайная церемония, китайское место, чай, китайский, набор посуды, набор китайской посуды, Раздел Чая, Чай, Китайский чай, Китайская продукция, Тель-Авив, Израиль" />
            </Helmet>
        </EventsDivStyled>
        </>
        
    );
};


const EventsDivStyled = styled.div`
    font-family: Arial, Helvetica, sans-serif;
    box-sizing: border-box;
    width: 80%;
    color: rgb(52, 48, 48);
    margin: auto;
`;
const ContainerDiv = styled.div`
    margin: 0 auto;
    width: 50%;
    height: 32vh;
    border-color: white;
    @media(max-width: 768px){
      height: 23vh;
      h2{
      font-size: 0.6rem;
    }
`;
const ContentDiv = styled.div`
    text-align: center;
    margin-bottom: 30px;
`;
const HeaderDiv = styled.div`
    font-size: 30px;

    @media(max-width: 768px){
      font-size: 18px;
      margin-bottom: 10px; 
    }

`;
const ImageVideoBodyDiv = styled.div`
    width: 160px;
    aspect-ratio: 1/1;
    display: inline-block;
    border: 2px solid green;
    border-radius: 5px;
    &:hover{
        cursor: pointer;

    }
    @media(max-width: 768px){
      width: 90px;
    }
    img{
        display: flex;
        margin: 0 auto;
        height: 20vh;
        object-fit: cover;
        width: 100%;
        height: 100%;
        
    }
    video{
        display: flex;
        margin: 0 auto;
        height: 20vh;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
`;


export default Events;

