import React from 'react';
import styled, { keyframes } from 'styled-components';

const CartLoading = () => {
  return (
    <LoadingContainer>
      <LoadingSpinner />
    </LoadingContainer>
  );
};
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoadingSpinner = styled.div`
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top: 5px solid #2e4f24;  
  border-radius: 50%;
  width: 50px;  
  height: 50px;
  animation: ${spin} 2s linear infinite;
  margin: auto;
`;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25vh;  
`;
export default CartLoading;