import React from 'react';
import Categories from './Categories/Categories';
import Choice from './Choice/Choice';
import Events from './Events/Events';

function Main() {
  return (
    <div>
        <Categories/>
        <Choice/>
        <Events/>
    </div>
  )
}


export default Main