import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useCategories } from '../Categories/CategoriesContent';
import baseUrl from '../../baseURL';
import Creatable from 'react-select/creatable';
import { useCart } from '../Cart/CartContext';
import styled from 'styled-components';
import { FaArrowDown } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Loading from '../Loading'; 
import { FaPlus } from 'react-icons/fa';
import { components } from 'react-select';
import { Helmet } from 'react-helmet';
const CustomDropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <FaPlus /> 
    </components.DropdownIndicator>
  );
};
function AllProducts() {
  const { categories } = useCategories(); 
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Initialize loading state
  const [currentIndex, setCurrentIndex] = useState(0);
  const [changedPrices, setChangedPrices] = useState([]);
  const { addToCart } = useCart();
  const navigate = useNavigate();
  function isMobileDevice() {
    return (
        (typeof window.orientation !== "undefined") ||
        (navigator.userAgent.indexOf('IEMobile') !== -1)
    );
  }
  const [isMobile, setIsMobile] = useState(isMobileDevice()); // Initial check

useEffect(() => {
    // You might want to check on resize or other conditions as well
    window.addEventListener('resize', () => {
        setIsMobile(isMobileDevice());
    });

    return () => {
        window.removeEventListener('resize', () => {
            setIsMobile(isMobileDevice());
        });
    };
}, []);
  const fetchProducts = async () => {
    setIsLoading(true); // Set loading to true at the beginning of fetch
    try {
      if (currentIndex < categories.length) {
        const categoryId = categories[currentIndex].id;
        const response = await axios.get(`${baseUrl}/tea-house/products?categoryId=${categoryId}`);
        setProducts(prevProducts => [...prevProducts, ...response.data]);
        const defaultPrices = response.data.map(product => product.price);
        setChangedPrices(prevPrices => [...prevPrices, ...defaultPrices]);
        setCurrentIndex(currentIndex + 1);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false); // Set loading to false after fetch operation is completed
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []); // Dependency array is empty to run only once after the initial render

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      top: '-70px',
      left: '-10px',
      width: '200px',
      textAlign: 'center',
      cursor: 'pointer',
      placeHolder: getPlaceholder(),
      width: '200%',
      zIndex: '1000'
    }),
    control:(provided) => ({
      ...provided,
      // Adjustments to the control's size (height, font size, etc.)
      minHeight: '5px', // Adjust height as needed
      height: '27px',
      margin: 'auto',
      marginTop: '10px',
      display: 'flex', // Smaller font size for options
      alignItems: 'center',
      cursor: 'pointer',

    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      alignSelf: 'center', // Try 'center' or adjust as needed to align with the text
      padding: '0 8px', // Adjust padding as needed, can reduce if the space is too large
    }),
    input: (provided) => ({
      ...provided,
      outline: 'none', /* Remove the default outline */
      border: 'none',
      cursor: 'none',
      opacity: '0' /* Remove the default border */
    }),
    placeholder: (provided) => ({
      ...provided,
      cursor: 'none',
      opacity: '0' /* Remove the default outline */
    })
    
  };
  const getPlaceholder = () => {
    return 'opacity: 0px'
  }
  const ChangePrice = (selectedOption, currentIndex) => {
    if (selectedOption) {
      const result = (selectedOption.value / products[currentIndex].amount) * products[currentIndex].price;
      const updatedPrices = [...changedPrices];
      updatedPrices[currentIndex] = result.toFixed(2);
      setChangedPrices(updatedPrices);
    }
  }

  const navigateToProduct = (product) => {
    navigate(`/tea-house/products/${product?.id}`, { state: { product } });
  }

  if (isLoading) {
    return <Loading />; // Render Loading component while data is loading
  }
  return (
    <AllProductsDiv>
      <Helmet>
                    <meta name="description" content="Выбор чая Выбор китайского чая, китайский набор, столик для чая, столик для китайского чая, чайная продукция, китайская утварь, церемония, китайская чайная, китайская чайная в Израиле, китайская чайная тель-авив, попить чай, попить китайский чай, заказать мероприятие, мероприятие, чайная церемония, китайское место, чай, китайский, набор посуды, набор китайской посуды, Раздел Чая, Чай, Китайский чай, Китайская продукция, Тель-Авив, Израиль" />
                    <meta name="keywords" content="Выбор чая Выбор китайского чая, китайский набор, столик для чая, столик для китайского чая, чайная продукция, китайская утварь, церемония, китайская чайная, китайская чайная в Израиле, китайская чайная тель-авив, попить чай, попить китайский чай, заказать мероприятие, мероприятие, чайная церемония, китайское место, чай, китайский, набор посуды, набор китайской посуды, Раздел Чая, Чай, Китайский чай, Китайская продукция, Тель-Авив, Израиль" />
            </Helmet>
      {products.map((product, currentIndex) => (
        <EveryProductDiv key={product.id}>
          <ImgTitleDiv>
          <Helmet>
                    <meta name="description" content={`${product?.description}`} />
                    <meta name="keywords" content={`${product?.title}`} />
            </Helmet>
            <img src={product?.imageURL} alt={product.title} onClick={() => navigateToProduct(product)}/>
            <h3 style={{marginTop: '12px'}}>{product.title}</h3>
          </ImgTitleDiv> 
          
          <AmountPriceDiv>
            <AmountDiv>
              <Creatable 
                isSearchable={!isMobile} 
                menuPosition="absolute"
                styles={customStyles}
                placeholder='amount' 
                defaultValue={{value: product.amount, label: product.amount}}
                options={[
                  {value: product.amount, label: product.amount},
                  {value: (product.amount * 2), label: product.amount * 2},
                  {value: (product.amount * 3), label: product.amount * 3},
                  {value: (product.amount * 4), label: product.amount * 4},
                  {value: (product.amount * 5), label: product.amount * 5}
                ]}
                onChange={(selectedOption) => ChangePrice(selectedOption, currentIndex)}
                components={{ DropdownIndicator: CustomDropdownIndicator }}

              />
            </AmountDiv>
            <PriceDiv className="price">Цена: <strong>&#8362; {changedPrices[currentIndex]}</strong></PriceDiv>
          </AmountPriceDiv>
          <ButtonDiv>
            <button onClick={() => addToCart(product, changedPrices[currentIndex])}>Добавить</button>
          </ButtonDiv>
        </EveryProductDiv>
      ))}
      {currentIndex < categories.length - 1 && <ButtonArrowDown onClick={fetchProducts}><FaArrowDown/>
</ButtonArrowDown> }
      
    </AllProductsDiv>
  );
}

export default AllProducts;  
  
const AllProductsDiv = styled.div`
    padding: 190px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    max-width: 60%; 
    margin: 0 auto; 
    @media(max-width: 768px){
      padding: 110px 15px;
    }
`;

const EveryProductDiv = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid green;
  padding: 10px 0;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ImgTitleDiv = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media(max-width: 768px){
    flex-direction: column;
  }
  img {
    width: 100px;
    height: 100px;
    margin-right: 25px;
    cursor: pointer;

    @media (max-width: 768px) {
      width: 70px;
      height: 70px;
      margin: auto;
      margin-top: 5px;
    }
  }

  h3 {
    font-size: 17px;

    @media (max-width: 768px) {
      font-size: 12px;
      margin-top: -2px;
    }
  }
`;
const AmountPriceDiv = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center; // Space out the amount and price
  flex-direction: column;
  
  @media (max-width: 768px) {
    align-items: center;
  }
`;

const AmountDiv = styled.div`
  display: flex;
  align-items: center;
`;

const PriceDiv = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  font-size: 15px;
  color: green;
  white-space: nowrap; // Ensure no wrapping
  margin-top: 10px;
  @media (max-width: 768px) {
    margin: auto;
    margin-top: 10px;
    font-size: 12px;
  }
`;

const ButtonDiv = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  button {
    width: 180px;
    height: 40px;
    background-color: #2e4f24;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;

    @media (max-width: 768px) {
      width: 120px;
      height: 30px;
      font-size: 12px;
    }
  }
`;
const ButtonArrowDown = styled.button`
  background-color: #eae3d1; // Neutral color for the button background
  color: #4CAF50; // Green color for the icon
  border: none;
  cursor: pointer;
  width: 50px; // Fixed width for a circular shape
  height: 50px; // Fixed height for a circular shape
  border-radius: 50%; // Fully rounded borders to create a circle
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); // Subtle shadow for 3D effect
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.2s; // Smooth transitions for interactive effects
  margin: auto;
  &:hover, &:focus {
    background-color: #4CAF50; // Change background color on hover/focus
    color: #fff; // Change icon color on hover/focus
    box-shadow: 0 6px 12px rgba(0,0,0,0.2); // Enhance shadow on hover/focus
    transform: translateY(-2px); // Slight raise effect on hover/focus
  }

  &:active {
    transform: translateY(1px); // Slight press-down effect on click
  }
`;



      
   

    






