import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './components/NavAndFooter/Navbar';
import MovingNavbar from './components/NavAndFooter/MovingNavbar';
import Main from './components/Main';
import Products from './components/Products/Products';
import OneProductItem from './components/Products/OneProductItem';
import Footer from './components/NavAndFooter/Footer';
import AllProducts from './components/Products/AllProducts';
import AllEvents from './components/Events/AllEvents';
import Event from './components/Events/OneEvent';
import About from './components/About/About';
function App() {
  return (
      <BrowserRouter>
      <Navbar/>
      <MovingNavbar/>
          <Routes>
                <Route exact path='/' element={<Main/>}/>
                <Route exact path='/tea-house/categories/:id/productsOfCategory' element={<Products/>}/>
                <Route exact path='/tea-house/products/:id' element={<OneProductItem/>}/>
                <Route exact path='/tea-house/allproducts' element={<AllProducts/>}/>
                <Route exact path='tea-house/allevents' element={<AllEvents/>}/>
                <Route exact path='/tea-house/events/:id' element={<Event/>} />
                <Route exact path='/about' element={<About/>}/> 
          </Routes>
          <Footer/>
      </BrowserRouter>
    );
}

export default App;
