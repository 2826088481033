import React, { useState, useEffect } from 'react';
import styled, {keyframes} from 'styled-components';
import { FaArrowCircleDown, FaArrowCircleUp } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useCategories } from './CategoriesContent';
import Loading from '../Loading';
import { Helmet } from 'react-helmet';

function Categories() {
    const { categories } = useCategories();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [expandedId, setExpandedId] = useState(null);
    const [showDescription, setShowDescription] = useState(false);

    useEffect(() => {
        if (categories.length > 0) {
            setIsLoading(false);
        }
    }, [categories]);

    const toggleExpand = (id) => {
        if (expandedId === id) {
            // Toggle off
            setExpandedId(null);
            setShowDescription(false);
        } else {
            // Toggle on
            setExpandedId(id);
            setShowDescription(false);
        }
    };

    const toggleDescription = () => {
        setShowDescription(!showDescription);
    };
    const navigateToCategory = (e, id) => {
        e.stopPropagation();
        navigate(`/tea-house/categories/${id}/productsOfCategory`)
    } 
    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            <Helmet>
                <meta name="description" content="Detailed description here" />
                <meta name="keywords" content="Keywords here" />
            </Helmet>
            <CategoriesDiv>
                {categories.map((category) => (
                    <CategoryContainer key={category.id} expanded={expandedId === category.id}>
                        <CategoryUL>
                            <CategoryLI onClick={() => toggleExpand(category.id)}>
                                {category.title}
                                <img src={category?.imageURL} alt={category?.title} />
                            </CategoryLI>
                        </CategoryUL>
                        {expandedId === category.id && (
                            <>
                                <CategoryContent onClick={(e) => navigateToCategory(e, category?.id)}>
                                    {!showDescription ? (
                                        <CategoryImage src={category?.imageURL} alt={category.title} />
                                    ) : (
                                        <CategoryDescription><p>{category.description}</p></CategoryDescription>
                                    )}
                                </CategoryContent>
                                {!showDescription ? (
                                    <ArrowIconDiv onClick={toggleDescription}>
                                        <FaArrowCircleDown /> 
                                    </ArrowIconDiv> ) : (
                                    <ArrowIconDiv onClick={toggleDescription}>
                                        <FaArrowCircleUp/>
                                    </ArrowIconDiv>
                                    )
                                }
                            </>
                        )}
                    </CategoryContainer>
                ))}
            </CategoriesDiv>
        </>
    );
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const CategoriesDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    padding-top: 160px;
`;

const CategoryContainer = styled.div`
    width: 100%;
    max-width: 600px;
    margin: 10px;
    border: 2px solid beige;
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.3s ease;
    cursor: pointer;
    box-shadow: ${({ expanded }) => expanded ? '0 8px 16px rgba(0,0,0,0.2)' : 'none'};
    &:hover {
        box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    }
    @media(max-width: 768px) {
        max-width: 270px;
    }
`;

const CategoryUL = styled.ul`
    list-style: none;
    margin: 0;
    padding: 10px;
    background: white;
`;

const CategoryLI = styled.li`
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 18px;
    color: #fff;
    font-weight: 900; // Text color
    text-align: center;
    cursor: pointer;
    padding: 10px 20px;
    background: linear-gradient(120deg, #e8f5e9, #c8e6c9); // Light green gradients
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
    @media(max-width: 768px){
        justify-content: space-between;
        font-size: 14px;
        color: green;
        font-weight: 600;
    }
    img{
        width: 15%;
        height: 15%;
        border-radius: 50%;
        margin-left: auto;
        @media(max-width: 768px){
            width: 25%;
            height: 25%;
        }
    }
    &:hover {
        color: #1b5e20; // Dark green for hover text color
        transform: translateY(-3px);
        box-shadow: 0 10px 20px rgba(0,0,0,0.2);
    }

    &::before {
        content: '';
        position: absolute;
        top: -50%;
        left: -50%;
        width: 200%;
        height: 200%;
        background-image: radial-gradient(circle, #a5d6a7, #66bb6a, #388e3c); // Green radial gradient
        transition: all 0.7s ease-out;
        z-index: -1;
        opacity: 0;
    }

    &:hover::before {
        transform: rotate(20deg);
        top: -30%;
        left: -30%;
        opacity: 1;
    }

    &:active {
        transform: translateY(-1px);
        box-shadow: 0 5px 10px rgba(0,0,0,0.15);
    }
`;



const CategoryContent = styled.div`
    position: relative;
    height: 300px;
    transition: transform 0.3s ease-in-out;
    background-color: white;
    @media(max-width: 768px){
        height: 190px;
    }
`;


const CategoryImage = styled.img`
    width: 100%;
    height: 100%;
    display: block;
    object-fit: content;
    animation: ${fadeIn} 0.5s ease-out;

`;

const CategoryDescription = styled.div`
    position: absolute;
    color: green;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 16px;
    text-align: center;
    animation: ${fadeIn} 0.5s ease-out;

    p{
        overflow-wrap: break-word;
        padding-right: 12px;
        padding-left: 12px;
        text-align:center;
    }

`;

const ArrowIconDiv = styled.div`
    text-align: center;
    font-size: 30px; // Consider increasing if the icon is too small
    color: #eae3d1;
    padding: 10px;
    cursor: pointer;
    background-color: white;
`;



// const fadeIn = keyframes`
//     from {
//         opacity: 0;
//         transform: scale(0.9);
//     }
//     to {
//         opacity: 1;
//         transform: scale(1);
//     }
// `;

// const CategoriesStyled = styled.div`
//     // display: flex;
//     // flex-wrap: wrap;
//     // justify-content: center;
//     width: 100%;
//     text-align: center;
//     padding-top: 145px;
//     margin-bottom: 20px;
//     // @media(max-width: 768px){
//     //     padding-top: 100px;
//     //     margin-bottom: 0px;
//     // }
    
// `;

// const CategoryStyled = styled.div`
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     width: 380px;
//     position: relative;
//     font-size: 1.2rem;
//     margin: 2rem;
//     margin-bottom: 50px;
//     cursor: pointer;
//     transition: all 0.3s ease-in-out;
//     border: 2px solid #2e4f24;
//     border-radius: 15px;
//     background-image: linear-gradient(to bottom, rgba(234, 227, 209, 1), rgba(255, 255, 255, 0.5));
//     @media(max-width: 768px){
//         width: 110px;
//     }
//     @media(max-width: 320px){
//         font-size: 1rem;
//     }

//     img {
//         width: 70%;
//         transition: opacity 0.3s ease-in-out;
//         @media(max-width: 768px){
//             width: 90%;
//             margin-top: 3px;
//         }
//     }
// `;


// const CategoryContent = styled.div`
//         width: 100%;
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         justify-content: center;
// `;

// const CategoryNameStyled = styled.div`
//     color: #2e4f24;
//     cursor: pointer;
//     width: 60%;
//     margin: auto;
//     margin-top: -15px;
//     margin-bottom: 10px;
//     font-weight: 900;
//     @media(max-width: 768px){
//         width: 100%;
//         border-bottom: 0;
//         margin-bottom: 0px;
//     }
// `;

// const CategoryDescriptionStyled = styled.div`
//         animation: ${fadeIn} 0.5s ease-out forwards;
//         opacity: 0;
//         width: 90%;
// `;

// const ArrowIconDivStyled = styled.div`
//     cursor: pointer;
//     font-size: 21px;
//     color: green;
//     position: absolute;
//     bottom: 10px;
//     @media(max-width: 768px){
//         font-size: 14px;
//         color: green;
//         margin: auto;
//         bottom: 7px;
//     }
//     @media(max-width: 320px){
//         font-size: 10px;
//         bottom: 5px;
//     }
// `;
{/* <CategoriesStyled>
            {categories.map((category, index) => (
                <CategoryStyled key={category.id}>
            

                    {expandedIndex === index ? (
                        <>
                            <ArrowIconDivStyled onClick={() => toggleDescription(index)}>
                                <FaArrowCircleDown />
                            </ArrowIconDivStyled>
                            <CategoryDescriptionStyled animate>{category.description}</CategoryDescriptionStyled>

                        </>
                    ) : (
                        <>
                        <Helmet>
                            <meta name="keywords" content={`${category?.title}, `} />

                
                        </Helmet>
                            <CategoryContent onClick={() => navigateToCategory(category?.id)}>
                                <CategoryNameStyled onClick={() => toggleDescription(index)}>
                                    {category?.title}
                                </CategoryNameStyled>
                                <img src={category?.imageURL} alt="category" />
                            </CategoryContent>
                            <ArrowIconDivStyled onClick={() => toggleDescription(index)}>
                                <FaArrowCircleDown />
                            </ArrowIconDivStyled>
                        </>
                    )}
                </CategoryStyled>
            ))}
           
        </CategoriesStyled> */}
export default Categories;

